import React from 'react'
import { Link } from 'react-router-dom'
import { PageHeader, Pagination, Table, Tooltip, Button } from 'antd'
import { ProductInstance } from '@antegma/accallio-cloud-tenant-api'
import { useDispatch } from 'react-redux'
import { UserAddOutlined } from '@ant-design/icons'
import { changeSelectedProductInstanceId } from '~/redux/product-instance/actions'


type ProductInstanceTableProps = {
    productInstancesState: {
        productInstances: ProductInstance[]
        page: number
        total: number
        pageSize: number
    }
    openMemberModal: (isModalOpen: boolean) => void;
}

const ProductInstanceTable = ({ productInstancesState, openMemberModal }: ProductInstanceTableProps): JSX.Element => {
    const dispatch = useDispatch()
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            render: (text: string, item: any): JSX.Element => (
                <Link to={`product-instance/${item.id}`}>
                    {text}
                </Link>
            ),
        },
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            render: (text: any): string => `${text}`,
        },
        {
            title: 'Product',
            dataIndex: 'product',
            key: 'product',
            render: (product: any): string => `${product.name}`,
        },
        {
            title: 'Action',
            key: 'action',
            render: (item: any): JSX.Element => {
                return (
                    <Tooltip placement="left" title="Add Member">
                        <Button type="link" onClick={() => {
                            openMemberModal(true);
                            dispatch(changeSelectedProductInstanceId(item.id));
                        }}>
                            <UserAddOutlined />
                        </Button>
                    </Tooltip>
                )
            },
        },
    ]

    return (
        <PageHeader title="Product Instances">
            <Table columns={columns} dataSource={productInstancesState.productInstances} pagination={false} />
            <Pagination defaultCurrent={productInstancesState.page} total={productInstancesState.total} pageSize={productInstancesState.pageSize} />
        </PageHeader>
    )
}

export default ProductInstanceTable;