import { ProductInstanceMember } from '@antegma/accallio-cloud-tenant-api'
import { AnyAction } from 'redux'

const actions = {
    GET_PRODUCT_INSTANCE_MEMBERS: 'PRODUCT_INSTANCE_MEMBER/GET_PRODUCT_INSTANCE_MEMBERS',
    ADD_PRODUCT_INSTANCE_MEMBER: 'PRODUCT_INSTANCE_MEMBER/ADD_PRODUCT_INSTANCE_MEMBER',
    DELETE_PRODUCT_INSTANCE_MEMBER: 'PRODUCT_INSTANCE_MEMBER/DELETE_PRODUCT_INSTANCE_MEMBER',
    CHANGE_PAGINATION: 'PRODUCT_INSTANCE_MEMBER/CHANGE_PAGINATION',
    GET_STATE: 'PRODUCT_INSTANCE_MEMBER/GET_STATE',
    SET_STATE: 'PRODUCT_INSTANCE_MEMBER/SET_STATE',
    RESET: 'PRODUCT_INSTANCE_MEMBER/RESET',

}
export default actions

export const getProductInstanceMembers = (page: number, pageSize: number, productInstancId: string, userId: string) => {
    return {
        type: actions.GET_PRODUCT_INSTANCE_MEMBERS,
        payload: {
            page,
            pageSize,           
            productInstancId,
            userId
        },
    }
}

export const addProuctInstanceMember = (productInstanceMember: ProductInstanceMember): AnyAction => {
    return {
        type: actions.ADD_PRODUCT_INSTANCE_MEMBER,
        payload: {
            productInstanceMember,
        },
    }
}

export const deleteProductInstanceMember = (id: string): AnyAction => {
    return {
        type: actions.DELETE_PRODUCT_INSTANCE_MEMBER,
        payload: {
            id,
        },
    }
}

export const changePagination = (page: number, pageSize: number): AnyAction => {
    return {
        type: actions.CHANGE_PAGINATION,
        payload: {
            page,
            pageSize
        },
    }
}

export const reset = () => {
    return {
        type: actions.RESET,
    }
}
