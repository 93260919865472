/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AnyAction } from 'redux'
import { all, put, takeEvery, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { TDefaultSagas } from '@antegma/accallio-cloud-react-components/lib/app/redux/interface'
import productInstanceApi from '~/services/product-instances/productInstanceApi'
import actions from './actions'


export function* GET_PRODUCT_INSTANCES({ payload }: AnyAction) {
    const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }));

    yield put({
        type: actions.SET_STATE,
        payload: {
            
            loading: true,
        },
    })

    const options = { headers: { Authorization: `Bearer ${accessToken}` } };
    const { page, pageSize } = payload;

    const response = yield productInstanceApi.getProductInstancesByTenant(page, pageSize, options).catch((error) => {
        notification.error({
            message: 'Products Instance: cannot load Product Instances',
            description: error.message,
        })
    })

    yield put({
        type: actions.SET_STATE,
        payload: {
            loading: false,
            productInstances: response.data.items,
            page: response.data.page,
            total: response.data.total,
        },
    })
}
/**
 *
 */
export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_PRODUCT_INSTANCES, GET_PRODUCT_INSTANCES),
    ])
}
