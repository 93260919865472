/* eslint-disable no-undef */
import React from 'react'
import ReactDOM from 'react-dom'

import App from '@antegma/accallio-cloud-react-components/lib/app'
import { accallioLibInfo } from '@antegma/accallio-cloud-react-components/lib/app/util/env'
import { TRouterProps } from '@antegma/accallio-cloud-react-components/lib/app/router'

import * as languages from 'Assets/languages'
import sagas from 'Redux/sagas'
import reducers from 'Redux/reducers'
import pages from '~/pages'
import * as serviceWorker from './serviceWorker'

import 'Assets/global.scss'

console.log(accallioLibInfo.libVersion)

const router: TRouterProps = {
    indexUrl: '/settings',
    pages,
}

const redux = {
    sagas,
    reducers,
}

ReactDOM.render(<App languages={languages} name="Admin" redux={redux} router={router} hideTenantSwitch />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
