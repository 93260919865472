import { Button, Row, Select } from "antd";
import React, { useState } from "react";


const AddUserInput = () => {
    const [value, setValue] = useState<string>();
    const [isSelected, setisSelected] = useState<boolean>(false);

    const handleSearch = (newValue: string) => {
        if (newValue) {
            fetch(newValue);
        }
    };

    const handleChange = (newValue: string) => {
        setValue(newValue);
    };



    return (
        <Row gutter={24} >
            <Select
                showSearch
                value={value}
                placeholder="Search for a user"
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={handleSearch}
                onChange={handleChange}
                onSelect={() => setisSelected(true)}
                notFoundContent={null}
                style={{ marginRight: '16px', width: '300px' }}
            />
            <Button
                type="primary"
                disabled={!isSelected}
            >Add</Button>
        </Row>
    )
}
export default AddUserInput;