import React from 'react'
import { Helmet } from 'react-helmet'
import { Row, Col, Card, PageHeader } from 'antd'
import { Link } from 'react-router-dom'
import Subscriptions from './subscriptions'

const Settings = (): JSX.Element => {
    return (
        <>
            <Helmet title="Settings" />
            <PageHeader title="Settings">
                <Row gutter={16}>
                    <Col span={8}>
                        <Card bordered extra={<Link to="/settings/product-instances">Edit Product Instances</Link>} title="Product Instances" />
                    </Col>
                    {/* <Col span={8}>
                        <Card bordered extra={<Link to="/settings/connections">Edit Connections</Link>} title="Connections" />
                    </Col>
                    <Col span={8}>
                        <Card bordered extra={<Link to="/settings/eventproviders">Edit Event Providers</Link>} title="Event Providers" />
                    </Col>
                    <Col span={8}>
                        <Card bordered extra={<Link to="/settings/tenantusers">Edit Tenant Users</Link>} title="Tenant Users" />
                    </Col>
                    <Col span={8}>
                        <Card bordered extra={<Link to="/settings/serviceaccounts">Edit Service Accounts</Link>} title="Service Accounts" />
                    </Col> */}
                </Row>
            </PageHeader>
        </>
    )
}

export default {
    name: 'Settings',
    routeProps: {
        path: '/settings',
        component: Settings,
    },
    children: [Subscriptions],
    navProps: {
        title: 'Settings',
        icon: 'setting',
    },
}
