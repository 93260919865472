/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react'
import { PageHeader } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import actions from 'Redux/tenant-users/actions'
import { TSagas } from 'Redux/interface'
import ProductInstanceTable from '~/components/ProductInstance/ProductInstanceTable'
import { getProductInstances } from '~/redux/product-instance/actions'
import ProductInstanceMemberModal from '~/components/Modal/AddMember/ProductInstanceMemberModal'


const ProductInstances = (): JSX.Element => {
    const dispatch = useDispatch();
    const productInstanceState = useSelector((state: TSagas) => state.productInstances);
    const [isMemberModalOpen, setIsMemberModalOpen] = useState(false);

    useEffect(() => {
        dispatch(getProductInstances(1, 100));
    }, [productInstanceState.page, productInstanceState.pageSize])

    React.useLayoutEffect(() => {
        dispatch({
            type: actions.LOAD_STATE,
        })
    }, [dispatch])

    return (
        <PageHeader title="Overview">
            <ProductInstanceTable productInstancesState={productInstanceState} openMemberModal={setIsMemberModalOpen} />
            {isMemberModalOpen &&
                <ProductInstanceMemberModal isModalOpen={isMemberModalOpen} setIsModalOpen={setIsMemberModalOpen} />
            }
        </PageHeader>
    )
}

export default {
    name: 'Product Instances',
    routeProps: {
        path: '/product-instances',
        component: ProductInstances,
    },
    navProps: {
        title: 'Product Instances',
    },
}
